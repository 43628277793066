<template>
	<v-row class="analytic-employee-header">
		<v-col v-for="item of cItems" :key="item.rating">
			<AnalyticEmployeeHeaderItem :item="item" />
		</v-col>
	</v-row>
</template>

<script>
import AnalyticEmployeeHeaderItem from "@/components/AnalyticEmployeeHeaderItem";

export default {
	name: "AnalyticEmployeeHeader",

	components: {
		AnalyticEmployeeHeaderItem,
	},

	props: {
		items: { type: Array },
	},

	data() {
		return {
			ratingItems: [
				{ rating: 5, value: 0, percent: 0 },
				{ rating: 4, value: 0, percent: 0 },
				{ rating: 3, value: 0, percent: 0 },
				{ rating: 2, value: 0, percent: 0 },
				{ rating: 1, value: 0, percent: 0 },
			],
		};
	},

	computed: {
		cItems() {
			if (!this.items) return this.ratingItems;
			return this.items.reverse().map((e, i) => ({ ...e, rating: 5 - i }));
		},
	},
};
</script>

<style lang="scss" scoped></style>
