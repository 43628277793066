<template>
	<article class="analytic-employee-article">
		<AnalyticEmployeeHeader :items="cRatingItems" />

		<v-data-table
			class="overflow-x elevation-1 mt-5"
			height="100%"
			item-key="name"
			hide-default-footer
			:headers="headers"
			:items="cItems"
			:loading="loading"
			:page.sync="options.page"
			:items-per-page="options.itemsPerPage"
			@page-count="options.pageCount = $event"
		>
			<template v-slot:top>
				<div class="border-bottom">
					<h6 class="pa-4 text-h6 font-weight-light">Исполнители</h6>
				</div>
			</template>
			<template v-slot:footer>
				<v-pagination
					v-model="options.page"
					class="my-3"
					:length="options.pageCount"
				></v-pagination>
			</template>
			<template v-slot:[`item.avatar`]="{ item }">
				<td>
					<v-img :src="loadServerImage()"></v-img>
				</td>
			</template>
			<template v-slot:[`item.fio`]="{ item }">
				<td class="d-flex flex-column justify-center">
					<span>{{ item | fullname }}</span>
					<span class="grey--text">{{ item.employee_position.name }}</span>
				</td>
			</template>
			<template v-slot:[`item.number`]="{ item }">
				<Linebar
					:percent="item.analytic.percent"
					:value="item.analytic.value"
					label="выполенных заявок"
				/>
			</template>
			<template v-slot:[`item.user_rating`]="{ item }">
				<td>
					<v-rating
						color="amber"
						:value="item.user_rating"
						readonly
						dense
					></v-rating>
				</td>
			</template>
		</v-data-table>
	</article>
</template>

<script>
import { mapGetters } from "vuex";
import AnalyticEmployeeHeader from "@/components/AnalyticEmployeeHeader";
import Linebar from "@/components/app/Linebar";

export default {
	name: "AnalyticEmployeeArticle",

	components: {
		AnalyticEmployeeHeader,
		Linebar,
	},

	data() {
		return {
			headers: [
				{ text: "№", value: "index" },
				{ text: "", value: "avatar", sortable: false },
				{
					text: "ФИО сотрудника",
					value: "fio",
				},
				{
					text: "Кол-во исполненных заявок",
					value: "number",
				},
				{
					text: "Оценка от жителей",
					value: "user_rating",
				},
			],
			options: {
				page: 1,
				itemsPerPage: 10,
				pageStart: 1,
				pageCount: 1,
			},
			filter: {
				name: "",
			},
		};
	},

	computed: {
		...mapGetters({
			analytic: "ksk/GET_ANALYTIC",
			loading: "ksk/IS_LOADING_ANALYTIC",
		}),

		cItems() {
			if (!this.analytic) return [];
			return this.analytic.users.map((e, i) => ({ ...e, index: i + 1 }));
		},

		cRatingItems() {
			return this.analytic?.user_rating;
		},
	},

	created() {
		this.$store.dispatch("ksk/change_analytic_type", "employee");
	},

	mounted() {
		this.$store.dispatch("ksk/load_analytic");
	},
};
</script>

<style scoped lang="scss">
::v-deep tr {
	height: 60px !important;
}
</style>
