import { render, staticRenderFns } from "./AnalyticEmployeeArticle.vue?vue&type=template&id=3d8a41b8&scoped=true&"
import script from "./AnalyticEmployeeArticle.vue?vue&type=script&lang=js&"
export * from "./AnalyticEmployeeArticle.vue?vue&type=script&lang=js&"
import style0 from "./AnalyticEmployeeArticle.vue?vue&type=style&index=0&id=3d8a41b8&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d8a41b8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VImg } from 'vuetify/lib/components/VImg';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VDataTable,VImg,VPagination,VRating})
