<template>
	<v-card class="analytic-employee-header__item">
		<v-card-text class="d-flex flex-column">
			<div class="d-flex justify-space-between">
				<h6 class="text-h6">{{ item.rating }}.0</h6>
				<v-rating
					dense
					readonly
					small
					color="amber"
					:value="item.rating"
				></v-rating>
			</div>
			<div class="d-flex justify-space-between subtitle-1">
				<span>{{ item.value }}</span>
				<span>{{ item.percent }}%</span>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "AnalyticEmployeeHeaderItem",

	props: {
		item: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style></style>
