<template>
	<section class="analytic-report analytic-report-grid">
		<AnalyticEmployeeArticle />
		<AnalyticSidebar :show-paid="false" :show-categories="true" />
	</section>
</template>

<script>
import AnalyticEmployeeArticle from "@/components/AnalyticEmployeeArticle";
import AnalyticSidebar from "@/components/AnalyticSidebar";

export default {
	name: "AnalyticReportEmployee",

	components: {
		AnalyticEmployeeArticle,
		AnalyticSidebar,
	},
};
</script>

<style></style>
