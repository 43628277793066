var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"analytic-employee-article"},[_c('AnalyticEmployeeHeader',{attrs:{"items":_vm.cRatingItems}}),_c('v-data-table',{staticClass:"overflow-x elevation-1 mt-5",attrs:{"height":"100%","item-key":"name","hide-default-footer":"","headers":_vm.headers,"items":_vm.cItems,"loading":_vm.loading,"page":_vm.options.page,"items-per-page":_vm.options.itemsPerPage},on:{"update:page":function($event){return _vm.$set(_vm.options, "page", $event)},"page-count":function($event){_vm.options.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"border-bottom"},[_c('h6',{staticClass:"pa-4 text-h6 font-weight-light"},[_vm._v("Исполнители")])])]},proxy:true},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"my-3",attrs:{"length":_vm.options.pageCount},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})]},proxy:true},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-img',{attrs:{"src":_vm.loadServerImage()}})],1)]}},{key:"item.fio",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex flex-column justify-center"},[_c('span',[_vm._v(_vm._s(_vm._f("fullname")(item)))]),_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.employee_position.name))])])]}},{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('Linebar',{attrs:{"percent":item.analytic.percent,"value":item.analytic.value,"label":"выполенных заявок"}})]}},{key:"item.user_rating",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-rating',{attrs:{"color":"amber","value":item.user_rating,"readonly":"","dense":""}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }